import { ProductCategory } from '../../../../shared/models/productCategory';
import { DefaultLunch } from '../../../../shared/models/defaultlunch';
import { App } from './../../../../shared/models//app';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../../../../shared/models//user';
import { Response } from '../../../../shared/models//response';
import { Customer } from '../../../../shared/models//customer';
import { DataService } from './data.service';
import { Ad } from '../../../../shared/models//ad';
import { Template } from '../../../../shared/models//template';
import { Section } from '../../../../shared/models//section';
import { Media } from '../../../../shared/models//media';
import { Profile } from '../../../../shared/models//profile';
import { Serie } from '../../../../shared/models//serie';
import { ToastrService } from 'ngx-toastr';
import { Byline } from '../../../../shared/models//byline';
import { Place } from '../../../../shared/models//place';
import { Lunch } from '../../../../shared/models//lunch';
import { Event } from '../../../../shared/models//event';
import { Rss } from '../../../../shared/models//rss';
import { SportKey } from '../../../../shared/models//sportKey';
import { SportTeam } from '../../../../shared/models//sportTeam';
import { Contract } from '../../../../shared/models//contract';
import { Contact } from '../../../../shared/models//contact';
import { Page } from '../../../../shared/models//page';
import { SectionPage } from '../../../../shared/models//sectionPage';
import { Device } from '../../../../shared/models//device';
import { Emailtemplate } from '../../../../shared/models//emailtemplate';
import { Email } from '../../../../shared/models//email';
import { AppUser } from '../../../../shared/models//appuser';
import { EventCategory } from '../../../../shared/models//eventcategory';
import { Quiz } from '../../../../shared/models//quiz';
import { Recommend } from '../../../../shared/models//recommend';
import { RecommendCategory } from '../../../../shared/models//recommendCategory';
import { Product } from '../../../../shared/models//product';
import { GiftCard } from '../../../../shared/models//giftcard';
import { Todo } from '../../../../shared/models//todo';
import { AdTag } from '../../../../shared/models//adtag';
import { environment } from '../../environments/environment'
import { LiveEvent } from '../../../../shared/models//liveevent';
import { LiveEventEvent } from '../../../../shared/models//liveevent_event';
import { menuObject } from '../../../../shared/models/menuObject';
import { socialMediaObject } from '../../../../shared/models/socialMedia';
import { Feedback } from '../../../../shared/models//feedback';
import { CustomerLog } from '../../../../shared/models//customer_log';
import { AudioShow } from '../../../../shared/models/audioShow';
import { AudioEpisode } from '../../../../shared/models/audioEpisode';
import { VideoShow } from '../../../../shared/models//videoShow';
import { Today } from '../../../../shared/models//today';
import { CityQuizQuestion } from '../../../../shared/models/CityQuizQuestion';
import { Chat } from '../../../../shared/models/chat';
import { Notification } from '../../../../shared/models/notification';
import { Lesson } from '../../../../shared/models/lesson';
import { Chapter } from '../../../../shared/models/chapter';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl:string = environment.apiUrl;

  constructor(private http: HttpClient, private data: DataService, private toastr: ToastrService) { }

  public login(email:string, password:string){

    let postObject = {
      email: email,
      password: password
    }

    return this.http.post(this.baseUrl + '/api/login', postObject).pipe(map((data:Response) => {
      return data;
    }));
  }

  public send_restore(email:string)
  {
    let postObject = {
      email: email
    }

    return this.http.post(this.baseUrl + '/api/restore', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public send_welcome(id: number)
  {
    let postObject = {
      id: id
    }

    return this.http.post(this.baseUrl + '/api/welcome', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public send_pren(id: number)
  {
    let postObject = {
      id: id
    }

    return this.http.post(this.baseUrl + '/api/prenmail', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public send_notification(title: String ,body: String, target: String, link: string, image:string, app_id: number)
  {


    let postObject = {
      app_id: app_id,
      title: title,
      body: body,
      target: target,
      link: link,
      image: image
    }

    return this.http.post(this.baseUrl + '/api/postnotification', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSocialMedia(id: number)
  {
    return this.http.get(this.baseUrl + '/api/socialmedia?app_id=' + id, this.getAuthorizationHeader()).pipe(map((data:socialMediaObject[]) => {
      return data;
    })); 
  }

  public setSocialMedia(socialMediaObjects: socialMediaObject[], app_id: number)
  {

    let postObject = {
      id: app_id,
      socialmedias: socialMediaObjects
    }

    return this.http.post(this.baseUrl + '/api/socialmedia', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    })); 
  }

  public getMenu(id: number)
  {
    return this.http.get(this.baseUrl + '/api/menu?app_id=' + id, this.getAuthorizationHeader()).pipe(map((data:menuObject[]) => {
      return data;
    })); 
  }

  public setMenu(menuObjects: menuObject[], app_id: number)
  {

    let postObject = {
      id: app_id,
      menus: menuObjects
    }

    return this.http.post(this.baseUrl + '/api/menu', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    })); 
  }

  public getTabMenu(id: number)
  {
    return this.http.get(this.baseUrl + '/api/tabmenu?app_id=' + id, this.getAuthorizationHeader()).pipe(map((data:menuObject[]) => {
      return data;
    })); 
  }

  public setTabMenu(menuObjects: menuObject[], app_id: number)
  {

    let postObject = {
      id: app_id,
      menus: menuObjects
    }

    return this.http.post(this.baseUrl + '/api/tabmenu', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    })); 
  }

  public getUsers(){
    return this.http.get(this.baseUrl + '/api/users', this.getAuthorizationHeader()).pipe(map((data:User[]) => {
      return data;
    }));
  }

  public getEmployees(){
    return this.http.get(this.baseUrl + '/api/users?alt=employees', this.getAuthorizationHeader()).pipe(map((data:User[]) => {
      return data;
    }));
  }

  public getColumnists(appstr){

    return this.http.get(this.baseUrl + '/api/users?alt=columnists&appstr=' + appstr, this.getAuthorizationHeader()).pipe(map((data:User[]) => {
      return data;
    }));
  }



  public getUser(id: number){
    return this.http.get(this.baseUrl + '/api/user/' + id, this.getAuthorizationHeader()).pipe(map((data:User) => {
      return data;
    }));
  }

  public getUserByToken(token: string){
    return this.http.get(this.baseUrl + '/api/user/' + token, this.getAuthorizationHeader()).pipe(map((data:User) => {
      return data;
    }));
  }

  public setUser(user: User){
    return this.http.post(this.baseUrl + '/api/user', user, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delUser(id: number)
  {
    return this.http.get(this.baseUrl + '/api/user/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setUserAvatar(user_id: number, logo_str:string){

    let postObject = {
      id: user_id,
      avatar_str: logo_str
    }

    return this.http.post(this.baseUrl + '/api/user/avatar', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getAppUser(id: number){
    return this.http.get(this.baseUrl + '/api/appuser/' + id, this.getAuthorizationHeader()).pipe(map((data:AppUser) => {
      return data;
    }));
  }

  public setAppUser(user: AppUser){
    return this.http.post(this.baseUrl + '/api/postappuser', user, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delAppUser(id: number)
  {
    console.log(this.baseUrl + '/api/appuser/delete?id=' + id)
    return this.http.post(this.baseUrl + '/api/appuser/delete', {'id':id}, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public getNotification(id: number){
    return this.http.get(this.baseUrl + '/api/notification/' + id, this.getAuthorizationHeader()).pipe(map((data:Notification) => {
      return data;
    }));
  }

  public setNotification(notification: Notification){
    return this.http.post(this.baseUrl + '/api/notification', notification, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delNotification(id: number)
  {
    return this.http.get(this.baseUrl + '/api/notification/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public getApps(){
    return this.http.get(this.baseUrl + '/api/apps', this.getAuthorizationHeader()).pipe(map((data:App[]) => {
      return data;
    }));
  }

  public setApp(app: App){
    return this.http.post(this.baseUrl + '/api/app', app, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getApp(id: number){
    return this.http.get(this.baseUrl + '/api/app/' + id, this.getAuthorizationHeader()).pipe(map((data:App) => {
      return data;
    }));
  }

  public setPage(page: Page){
    return this.http.post(this.baseUrl + '/api/page', page, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getPage(id: number){
    return this.http.get(this.baseUrl + '/api/page/' + id, this.getAuthorizationHeader()).pipe(map((data:Page) => {
      return data;
    }));
  }

  public getPages(id: number){
    return this.http.get(this.baseUrl + '/api/pages?customer_id=' + id, this.getAuthorizationHeader()).pipe(map((data:Page[]) => {
      return data;
    }));
  }

  public delPage(id: number){
    return this.http.get(this.baseUrl + '/api/page/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getActiveChats(app_id: number){
    return this.http.get(this.baseUrl + '/api/activechats?app_id=' + app_id, this.getAuthorizationHeader()).pipe(map((data:Chat[]) => {
      return data;
    }));
  }

  public getChats(app_id: number, device_id: number) {

    return this.http.get(
      this.baseUrl+ `/api/chats/${device_id}?app_id=${app_id}&user_id=${this.data.currentUser.id}`, this.getAuthorizationHeader())
      .pipe(map((data: any[]) => data));
  }

  public postChat(postObject) {

    return this.http.post(
      this.baseUrl+ `/api/chat?app_id=${postObject.app_id}`, postObject, this.getAuthorizationHeader())
      .pipe(map((data: any[]) => data));
  }
  

  public setProduct(product: Product){
    return this.http.post(this.baseUrl + '/api/product', product, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getProduct(id: number){
    return this.http.get(this.baseUrl + '/api/product/' + id, this.getAuthorizationHeader()).pipe(map((data:Product) => {
      return data;
    }));
  }

  public getProducts(id: number){
    return this.http.get(this.baseUrl + '/api/products?customer_id=' + id, this.getAuthorizationHeader()).pipe(map((data:Product[]) => {
      return data;
    }));
  }

  public delProduct(id: Number){
    return this.http.get(this.baseUrl + '/api/product/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getProductCategories() {
    return this.http.get(this.baseUrl + '/api/productcategories?show=all', this.getAuthorizationHeader()).pipe(map((data:ProductCategory[]) => {
      return data;
    }));
  }

  public setContractImg(contract_id: Number, img_str:string){

    let postObject = {
      id: contract_id,
      img_str: img_str
    }

    return this.http.post(this.baseUrl + '/api/contract/img', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setCustomerLogo(customer_id: number, logo_str:string){

    let postObject = {
      id: customer_id,
      logo_str: logo_str
    }

    return this.http.post(this.baseUrl + '/api/customer/logo', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setCustomer(customer: Customer){
    return this.http.post(this.baseUrl + '/api/customer', customer, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getCustomer(id: number){
    return this.http.get(this.baseUrl + '/api/customer/' + id, this.getAuthorizationHeader()).pipe(map((data:Customer) => {
      return data;
    }));
  }

  public getCustomers(){
    return this.http.get(this.baseUrl + '/api/customers?uid=' + this.data.currentUser.id, this.getAuthorizationHeader()).pipe(map((data:Customer[]) => {
      return data;
    }));
  }

  public getCustomersByContract(apps: App[]){

    let appstr = "";

    if (apps)
    {
      apps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    return this.http.get(this.baseUrl + '/api/customersbycontract?uid=' + this.data.currentUser.id + "&appstr=" + appstr, this.getAuthorizationHeader()).pipe(map((data:Customer[]) => {
      return data;
    }));
  }

  public getCustomersWithwarnings(apps: App[]){

    let appstr = "";

    if (apps)
    {
      apps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    return this.http.get(this.baseUrl + '/api/customersbywarnings?uid=' + this.data.currentUser.id + "&appstr=" + appstr, this.getAuthorizationHeader()).pipe(map((data:Customer[]) => {
      return data;
    }));
  }



  public getSalesStat(apps: App[], timestart:string, timestop:string)
  {
    let appstr = "";

    if (apps)
    {
      apps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    return this.http.get(this.baseUrl + '/api/salestimeline?uid=' + this.data.currentUser.id + "&appstr=" + appstr + "&timestart=" + timestart + "&timestop=" + timestop , this.getAuthorizationHeader()).pipe(map((data:Customer[]) => {
      return data;
    }));

  }

  public getFollowers()
  {
    return this.http.get(this.baseUrl + '/api/followers?cid=' + this.data.currentUser.customer.id, this.getAuthorizationHeader()).pipe(map((data:Device[]) => {
      return data;
    }));
  }

  public getFollower(id: number)
  {
    return this.http.get(this.baseUrl + '/api/follower/' + id + '?cid=' + this.data.currentUser.customer.id, this.getAuthorizationHeader()).pipe(map((data:Device) => {
      return data;
    }));
  }

  public getSummary(app_id: number)
  {
    return this.http.get(this.baseUrl + '/api/summary?app_id=' + app_id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public clearSummary(app_id: number)
  {
    return this.http.put(this.baseUrl + '/api/summary?app_id=' + app_id, {}, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }  

  public getStat()
  {
    return this.http.get(this.baseUrl + '/api/stats?uid=' + this.data.currentUser.id + "&cid=" + this.data.currentUser.customer.id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public getNewsStat(week: Number)
  {
    return this.http.get(this.baseUrl + '/api/stats/news?week=' + week, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public delCustomer(id: number)
  {
    return this.http.get(this.baseUrl + '/api/customer/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setProfile(profile: Profile){
    return this.http.post(this.baseUrl + '/api/profile', profile, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getProfile(id: number){
    return this.http.get(this.baseUrl + '/api/profile/' + id, this.getAuthorizationHeader()).pipe(map((data:Profile) => {
      return data;
    }));
  }

  public setContact(contact: Contact){
    return this.http.post(this.baseUrl + '/api/contact?customer_id=' + this.data.currentUser.customer.id, contact, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setCustomerLog(customerlog: CustomerLog){
    return this.http.post(this.baseUrl + '/api/customerlog', customerlog, this.getAuthorizationHeader()).pipe(map((data:CustomerLog) => {
      return data;
    }));
  }

  public getContact(id: number){
    return this.http.get(this.baseUrl + '/api/contact/' + id, this.getAuthorizationHeader()).pipe(map((data:Contact) => {
      return data;
    }));
  }

  public delContact(id: number){
    return this.http.get(this.baseUrl + '/api/contact/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:Contact) => {
      return data;
    }));
  }



  public previewProfile(profile: Profile){
    return this.http.post(this.baseUrl + '/api/profile/preview', profile, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setAd(ad: Ad){
    return this.http.post(this.baseUrl + '/api/ad', ad, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getAd(id: number){
    return this.http.get(this.baseUrl + '/api/ad/' + id, this.getAuthorizationHeader()).pipe(map((data:Ad) => {
      return data;
    }));
  }

  public getAdTags(){
    return this.http.get(this.baseUrl + '/api/adtags', this.getAuthorizationHeader()).pipe(map((data:AdTag[]) => {
      return data;
    }));
  }


  public reImportAd(id: number){
    return this.http.get(this.baseUrl + '/api/ad/reimport/' + id, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public generatePDF(id: number){
    return this.http.get(this.baseUrl + '/api/ad/generatepdf/' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public delAd(id: number)
  {
    return this.http.get(this.baseUrl + '/api/ad/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public getMonthlyAds(c_id: number){
    return this.http.get(this.baseUrl + '/api/customer/monthstat?cid=' + c_id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data.result;
    }));
  }

  public previewAd(ad: Ad){
    return this.http.post(this.baseUrl + '/api/ad/preview', ad, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setSerie(serie: Serie){
    return this.http.post(this.baseUrl + '/api/serie', serie, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSerie(id: number){
    return this.http.get(this.baseUrl + '/api/serie/' + id, this.getAuthorizationHeader()).pipe(map((data:Serie) => {
      return data;
    }));
  }

  public getSeries(){
    return this.http.get(this.baseUrl + '/api/series', this.getAuthorizationHeader()).pipe(map((data:Serie[]) => {
      return data;
    }));
  }

  public previewSerie(serie: Serie){
    return this.http.post(this.baseUrl + '/api/serie/preview', serie, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setTemplate(template: Template){
    return this.http.post(this.baseUrl + '/api/template', template, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getTemplate(id: number){
    return this.http.get(this.baseUrl + '/api/template/' + id, this.getAuthorizationHeader()).pipe(map((data:Template) => {
      return data;
    }));
  }

  public getTemplates(){
    return this.http.get(this.baseUrl + '/api/templates', this.getAuthorizationHeader()).pipe(map((data:Template[]) => {
      return data;
    }));
  }

  public setEmailTemplate(template: Emailtemplate){
    return this.http.post(this.baseUrl + '/api/emailtemplate', template, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEmailTemplate(id: number){
    return this.http.get(this.baseUrl + '/api/emailtemplate/' + id, this.getAuthorizationHeader()).pipe(map((data:Emailtemplate) => {
      return data;
    }));
  }

  public delEmailTemplate(id: number){
    return this.http.get(this.baseUrl + '/api/emailtemplate/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEmailTemplates(){
    return this.http.get(this.baseUrl + '/api/emailtemplates', this.getAuthorizationHeader()).pipe(map((data:Emailtemplate[]) => {
      return data;
    }));
  }

  public getEmails(userid: number){
    return this.http.get(this.baseUrl + '/api/emails?uid=' + userid, this.getAuthorizationHeader()).pipe(map((data:Email[]) => {
      return data;
    }));
  }

  public setContract(contract: Contract){
    return this.http.post(this.baseUrl + '/api/contract', contract, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getContract(id: number){
    return this.http.get(this.baseUrl + '/api/contract/' + id, this.getAuthorizationHeader()).pipe(map((data:Contract) => {
      return data;
    }));
  }

  public delRecommend(id: number)
  {
    return this.http.get(this.baseUrl + '/api/recommend/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setRecommend(recommend: Recommend){
    return this.http.post(this.baseUrl + '/api/recommend?admin=1', recommend, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getRecommend(id: number){
    return this.http.get(this.baseUrl + '/api/recommend/' + id, this.getAuthorizationHeader()).pipe(map((data:Recommend) => {
      return data;
    }));
  }

  public getAllRecommends(){
    return this.http.get(this.baseUrl + '/api/recommends/all', this.getAuthorizationHeader()).pipe(map((data:Recommend[]) => {
      return data;
    }));
  }

  public getRecommendCats(){
    return this.http.get(this.baseUrl + '/api/recommend/categories?all=1', this.getAuthorizationHeader()).pipe(map((data:RecommendCategory[]) => {
      return data;
    }));
  }

  public setRecommendNonDuplicates(id, nid){
    return this.http.get(this.baseUrl + '/api/recommend-duplication?id=' + id + "&nid=" + nid, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public clearRecommendAdd(id){
    return this.http.get(this.baseUrl + '/api/recommend-clearadd?id=' + id , this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }


  public delAudioShow(id: number)
  {
    return this.http.get(this.baseUrl + '/api/audio-show/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setAudioShow(audioShow: AudioShow){
    return this.http.post(this.baseUrl + '/api/audio-show', audioShow, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getAudioShow(id: number){
    return this.http.get(this.baseUrl + '/api/audio-show/' + id, this.getAuthorizationHeader()).pipe(map((data:AudioShow) => {
      return data;
    }));
  }

  public getAudioShows(app_id: number){
    return this.http.get(this.baseUrl + '/api/audio-show?app_id=' + app_id, this.getAuthorizationHeader()).pipe(map((data:AudioShow[]) => {
      return data;
    }));
  }

  public getAudioShowsforApp(id: number){
    return this.http.get(this.baseUrl + '/api/audio-shows-for-admin?app_id=' + id, this.getAuthorizationHeader()).pipe(map((data:AudioShow[]) => {
      return data;
    }));
  }

  public syncAudioShow(episode_id: number, show_id: number){
    return this.http.get(this.baseUrl + '/api/audio-show/sync?episode_id=' + episode_id + "&show_id=" + show_id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public getAudioShowsEpisodes(id: number){
    return this.http.get(this.baseUrl + '/api/audio-show/' + id + '/episodes', this.getAuthorizationHeader()).pipe(map((data:AudioEpisode[]) => {
      return data;
    }));
  }
  
 

  public delVideoShow(id: number)
  {
    return this.http.get(this.baseUrl + '/api/video-show/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setVideoShow(videoShow: VideoShow){
    return this.http.post(this.baseUrl + '/api/video-show', videoShow, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getVideoShow(id: number){
    return this.http.get(this.baseUrl + '/api/video-show/' + id, this.getAuthorizationHeader()).pipe(map((data:VideoShow) => {
      return data;
    }));
  }



  public setPlace(place: Place){
    return this.http.post(this.baseUrl + '/api/place', place, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getPlace(id: number){
    return this.http.get(this.baseUrl + '/api/place/' + id, this.getAuthorizationHeader()).pipe(map((data:Place) => {
      return data;
    }));
  }

  public getPlaces(){
    return this.http.get(this.baseUrl + '/api/places', this.getAuthorizationHeader()).pipe(map((data:Place[]) => {
      return data;
    }));
  }

  public setSection(section: Section){
    return this.http.post(this.baseUrl + '/api/section', section, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSection(id: number){
    return this.http.get(this.baseUrl + '/api/section/' + id, this.getAuthorizationHeader()).pipe(map((data:Section) => {
      return data;
    }));
  }

  public getSections(customer_id: number = 0){
    return this.http.get(this.baseUrl + '/api/sections?cid=' + customer_id, this.getAuthorizationHeader()).pipe(map((data:Section[]) => {
      return data;
    }));
  }

  public setSectionPage(section: SectionPage){
    return this.http.post(this.baseUrl + '/api/sectionpage', section, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSectionPage(id: number){
    return this.http.get(this.baseUrl + '/api/sectionpage/' + id, this.getAuthorizationHeader()).pipe(map((data:SectionPage) => {
      return data;
    }));
  }

  public getSectionPages(customer_id: number = 0){
    return this.http.get(this.baseUrl + '/api/sectionpages?cid=' + customer_id, this.getAuthorizationHeader()).pipe(map((data:Section[]) => {
      return data;
    }));
  }

  public setMedia(media: Media){
    return this.http.post(this.baseUrl + '/api/media', media, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getMedia(id: number){
    return this.http.get(this.baseUrl + '/api/media/' + id, this.getAuthorizationHeader()).pipe(map((data:Media) => {
      return data;
    }));
  }

  public delMedia(id: number)
  {
    return this.http.get(this.baseUrl + '/api/media/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public getMedias(customer_id: number = 0, get: string = ""){
    return this.http.get(this.baseUrl + '/api/medias?customer_id=' + customer_id + "&get=" + get, this.getAuthorizationHeader()).pipe(map((data:Media[]) => {
      return data;
    }));
  }

  public searchMedias(customer_id: number = 0, s:String, get:string = ""){
    return this.http.get(this.baseUrl + '/api/medias?customer_id=' + customer_id + "&s=" + s + "&get=" + get, this.getAuthorizationHeader()).pipe(map((data:Media[]) => {
      return data;
    }));
  }

  public replaceMedia(media: Media, copy:Boolean){
    return this.http.post(this.baseUrl + '/api/media/replace?copy=' + copy, media, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setByline(byline: Byline){
    return this.http.post(this.baseUrl + '/api/byline', byline, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getByline(id: number){
    return this.http.get(this.baseUrl + '/api/byline/' + id, this.getAuthorizationHeader()).pipe(map((data:Byline) => {
      return data;
    }));
  }

  public getBylines(){
    return this.http.get(this.baseUrl + '/api/bylines', this.getAuthorizationHeader()).pipe(map((data:Byline[]) => {
      return data;
    }));
  }

  public setLunch(lunch: Lunch){
    return this.http.post(this.baseUrl + '/api/lunch?customer_id=' + this.data.currentUser.customer.id, lunch, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getLunch(id: number){
    return this.http.get(this.baseUrl + '/api/lunch/' + id, this.getAuthorizationHeader()).pipe(map((data:Lunch) => {
      return data;
    }));
  }

  public setStandardLunch(lunch: Lunch){
    return this.http.post(this.baseUrl + '/api/standardlunch?customer_id=' + this.data.currentUser.customer.id, lunch, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getStandardLunch(){
    return this.http.get(this.baseUrl + '/api/standardlunch?customer_id=' + this.data.currentUser.customer.id, this.getAuthorizationHeader()).pipe(map((data:any[]) => {
      return data;
    }));
  }

  public setDefaultLunch(lunch: DefaultLunch){
    return this.http.post(this.baseUrl + '/api/defaultlunch?customer_id=' + this.data.currentUser.customer.id, lunch, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getDefaultLunch(id: number){
    return this.http.get(this.baseUrl + '/api/defaultlunch/' + id, this.getAuthorizationHeader()).pipe(map((data:DefaultLunch) => {
      return data;
    }));
  }

  public delDefaultLunch(id: number)
  {
    return this.http.get(this.baseUrl + '/api/defaultlunch/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setQuiz(quiz: Quiz){
    return this.http.post(this.baseUrl + '/api/quiz', quiz, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getQuiz(id: number){
    return this.http.get(this.baseUrl + '/api/quiz/' + id, this.getAuthorizationHeader()).pipe(map((data:Quiz) => {
      return data;
    }));
  }

  public delQuiz(id: number)
  {
    return this.http.get(this.baseUrl + '/api/quiz/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setRss(rss: Rss){
    return this.http.post(this.baseUrl + '/api/rss', rss, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getRss(id: number){
    return this.http.get(this.baseUrl + '/api/rss/' + id, this.getAuthorizationHeader()).pipe(map((data:Rss) => {
      return data;
    }));
  }

  public setSportKey(sportKey: SportKey){
    return this.http.post(this.baseUrl + '/api/sportkey', sportKey, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSportKey(id: number){
    return this.http.get(this.baseUrl + '/api/sportkey/' + id, this.getAuthorizationHeader()).pipe(map((data:SportKey) => {
      return data;
    }));
  }

  public getSportKeys(){
    return this.http.get(this.baseUrl + '/api/sportkeys', this.getAuthorizationHeader()).pipe(map((data:SportKey[]) => {
      return data;
    }));
  }

  public setSportTeam(sportTeam: SportTeam){
    return this.http.post(this.baseUrl + '/api/sportteam', sportTeam, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getSportTeam(id: number){
    return this.http.get(this.baseUrl + '/api/sportteam/' + id, this.getAuthorizationHeader()).pipe(map((data:SportTeam) => {
      return data;
    }));
  }

  public setGiftcard(giftcard: GiftCard){
    return this.http.post(this.baseUrl + '/api/giftcard', giftcard, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getGiftcard(id: number){
    return this.http.get(this.baseUrl + '/api/giftcard/' + id, this.getAuthorizationHeader()).pipe(map((data:GiftCard) => {
      return data;
    }));
  }

  public setLiveEvent(event: LiveEvent){
    return this.http.post(this.baseUrl + '/api/livescoreevent', event, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public setLiveEventEvent(event: LiveEventEvent){
    return this.http.post(this.baseUrl + '/api/livescoreeventevent', event, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getLiveEvent(id: number){
    return this.http.get(this.baseUrl + '/api/livescoreevent/' + id, this.getAuthorizationHeader()).pipe(map((data:LiveEvent) => {
      return data;
    }));
  }

  public delLiveEvent(id: number)
  {
    return this.http.get(this.baseUrl + '/api/livescoreevent/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setEvent(event: Event){
    return this.http.post(this.baseUrl + '/api/event', event, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEvent(id: number){
    return this.http.get(this.baseUrl + '/api/event/' + id, this.getAuthorizationHeader()).pipe(map((data:Event) => {
      return data;
    }));
  }

  public delEvent(id: number)
  {
    return this.http.get(this.baseUrl + '/api/event/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public setEventCategory(event: EventCategory){
    return this.http.post(this.baseUrl + '/api/eventcategory', event, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getEventCategory(id: number){
    return this.http.get(this.baseUrl + '/api/eventcategory/' + id, this.getAuthorizationHeader()).pipe(map((data:EventCategory) => {
      return data;
    }));
  }

  public getEventCategories(id: number){
    return this.http.get(this.baseUrl + '/api/eventcategories?app_id=' + id, this.getAuthorizationHeader()).pipe(map((data:EventCategory[]) => {
      return data;
    }));
  }

  public delEventCategory(id: number)
  {
    return this.http.get(this.baseUrl + '/api/eventcategory/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public generateEvents(from, to, app_id){

    let postObject = {
      from: from,
      to: to,
      app: app_id
    }

    return this.http.post(this.baseUrl + '/api/generateevent', postObject, this.getAuthorizationHeader()).pipe(map((data) => {
      return data;
    }));
  }

  public getCityQuizQuestion(id: number)
  {
    return this.http.get(this.baseUrl + '/api/cityquizquestion/' + id, this.getAuthorizationHeader()).pipe(map((data:CityQuizQuestion) => {
      return data;
    }));   
  }


  public setcityQuestion(question: CityQuizQuestion){
    return this.http.post(this.baseUrl + '/api/cityquizquestion', question, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delcityQuizQuestion(id: number)
  {
    return this.http.get(this.baseUrl + '/api/cityquizquestion/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  

  public getTodo(id: number){
    return this.http.get(this.baseUrl + '/api/todo/' + id, this.getAuthorizationHeader()).pipe(map((data:Todo) => {
      return data;
    }));
  }

  public getTodos(uid: number){
    return this.http.get(this.baseUrl + '/api/todos?uid=' + uid, this.getAuthorizationHeader()).pipe(map((data: Todo[]) => {
      return data;
    }));
  }

  public setTodo(todo: Todo){
    return this.http.post(this.baseUrl + '/api/todo', todo, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public delTodo(id: number)
  {
    return this.http.get(this.baseUrl + '/api/todo/delete?id=' + id, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));
  }

  public getToday(id: number){
    return this.http.get(this.baseUrl + '/api/today/' + id, this.getAuthorizationHeader()).pipe(map((data:Today) => {
      return data;
    }));
  }

  public setToday(today: Today){
    return this.http.post(this.baseUrl + '/api/today', today, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getChapter(id: number){
    return this.http.get(this.baseUrl + '/api/chapter?id=' + id, this.getAuthorizationHeader()).pipe(map((data:Chapter) => {
      return data;
    }));
  }

  public setChapter(lesson: any){
    return this.http.post(this.baseUrl + '/api/chapter/', lesson, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getLesson(id: number){
    return this.http.get(this.baseUrl + '/api/lesson?id=' + id, this.getAuthorizationHeader()).pipe(map((data:Lesson) => {
      return data;
    }));
  }

  public setLesson(lesson: any){
    return this.http.post(this.baseUrl + '/api/lesson/', lesson, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getQuestions(id: number){
    return this.http.get(this.baseUrl + '/api/questions?id=' + id, this.getAuthorizationHeader()).pipe(map((data:Lesson) => {
      return data;
    }));
  }

  public setQuestions(questions: any){
    let postObject = {
      // test_id: test_id,
      questions: questions
    }
    return this.http.post(this.baseUrl + '/api/questions/', postObject, this.getAuthorizationHeader()).pipe(map((data:Response) => {
      return data;
    }));
  }

  public getFeedbacks(app_id: number)
  {
    return this.http.get(this.baseUrl + '/api/feedbacks?app_id=' + app_id, this.getAuthorizationHeader()).pipe(map((data:Feedback[]) => {
      return data;
    }));
  }


  public getDevicesPosition(apps: App[])
  {
    let appstr = "";

    apps.forEach(x => { appstr += x.id + ","; })

    appstr += "-1";

    return this.http.get(this.baseUrl + '/api/devices/positions?appstr=' + appstr, this.getAuthorizationHeader()).pipe(map((data:any) => {
      return data;
    }));

  }

  public uploadVideo(file){

    let formData = new FormData();
    formData.append('file', file);


    const url = this.baseUrl + '/api/uploadvideo?cid=' + this.data.currentUser.customer.id;
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public uploadAudio(file){

    let formData = new FormData();
    formData.append('file', file);


    const url = this.baseUrl + '/api/uploadaudio?cid=' + this.data.currentUser.customer.id;
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public showError(error: HttpErrorResponse)
  {
    this.toastr.error(error.message, "Ett fel uppstod", { progressBar: true, closeButton: true });
  }
  public showSuccess(title: string, message: string)
  {
    this.toastr.success(message, title, { progressBar: true, closeButton: true });
  }

  public getAuthorizationHeader()
  {

    let userid = "0";

    if (this.data.currentUser && this.data.currentUser.id)
    {
      userid = this.data.currentUser.id.toString();
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.data.apiToken}`,
      'User-id' : userid
    });

    const requestOptions = { headers: headers };

    return requestOptions;
  }

  public getDateStr(date_ob: Date)
  {
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }

}
