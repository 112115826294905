import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-school-test',
  templateUrl: './school-test.component.html',
  styleUrls: ['./school-test.component.scss']
})
export class SchoolTestComponent implements OnInit {
  public id: number;
  public pageData: any;
  public editId: number;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private api: ApiService,
  ) { 
    this.id = +this.route.snapshot.paramMap.get("id");
    if (this.id >= 1) {
      
      console.log("Test ID", this.id)
      
    } else {
      // Get new test ID
      console.log("Get new test ID")
    }
  }

  ngOnInit(): void {

    this.api.getQuestions(this.id).subscribe((data:any) => {
      this.pageData = data.data;
      console.log(this.pageData)
    })
  }

  add_row() {
    console.log('add row')
    let new_id = 0;
    this.pageData.forEach(item => {
      if (item.id > new_id)
      {
        new_id = item.id;
      }
    })

    let newObject:any = {}
  
    newObject.test_id = this.id;
    newObject.question = '';
  
    this.pageData.push(newObject);
    this.editId = new_id;
  }

  save() {
    console.log(this.pageData)
    this.api.setQuestions(this.pageData).subscribe((data:any) => {
      console.log(data)
    })

  }
}
