import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router, Route, ActivatedRoute } from '@angular/router';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { Ad } from '../../../../../shared/models/ad';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-school-lessons',
  templateUrl: './school-lessons.component.html',
  styleUrls: ['./school-lessons.component.scss']
})
export class SchoolLessonsComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  public id: number = 0
  public ads: Ad[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private http: HttpClient
    
  ) { 
    this.id = +this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    const that = this;

    let appstr = "";

    // if (this.selectedApps) {
    //   this.selectedApps.forEach(x => {
    //     appstr += x.id + ","
    //   })

    //   appstr += "-1"
    // }
    // console.log(this.selectedApps[0].id)

    this.dtOptions = {
      order: [[ 1, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Det finns ingen data i tabellen",
        "info":           "Visar rad _START_ till _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar post 0 till 0 av 0 poster",
        "infoFiltered":   "(av totalt _MAX_ poster)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget matchar sökningen",
        "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/lessons?chapter_id=' + this.id,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.ads = resp.data;
            console.log(that.ads);

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'title' }, { data: 'timestart' }]
    };
  }

  newLesson() {
    // console.log('/lesson/0/' + this.id)
    this.router.navigate(['/lesson/0/' + this.id])
  }

}
