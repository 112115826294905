import { CronicleEditComponent } from './Pages/cronicle-edit/cronicle-edit.component';
import { IntranetTaskEditComponent } from './Pages/intranet-task-edit/intranet-task-edit.component';
import { IntranetTasksComponent } from './Pages/intranet-tasks/intranet-tasks.component';
import { ProductsComponent } from './Pages/products/products.component';
import { DefaultLunchesComponent } from './Pages/default-lunches/default-lunches.component';
import { EventEditComponent } from './Pages/event-edit/event-edit.component';
import { EventsComponent } from './Pages/events/events.component';
import { BattleQuestionsComponent } from './Pages/battle-questions/battle-questions.component';
import { BattleQuestionEditComponent } from './Pages/battle-question-edit/battle-question-edit.component';
import { AdminRecommendsDuplicatesComponent } from './Pages/admin-recommends-duplicates/admin-recommends-duplicates.component';
import { AdminRecommendsMapComponent } from './Pages/admin-recommends-map/admin-recommends-map.component';
import { AdminRecommendsComponent } from './Pages/admin-recommends/admin-recommends.component';
import { QuizLiveComponent } from './Pages/quiz-live/quiz-live.component';
import { QuizzesComponent } from './Pages/quizzes/quizzes.component';
import { AdminEventCategoriesComponent } from './Pages/admin-event-categories/admin-event-categories.component';
import { SalesWarningsComponent } from './Pages/sales-warnings/sales-warnings.component';
import { AdminAppUserEditComponent } from './Pages/admin-app-user-edit/admin-app-user-edit.component';
import { StatsGeneralComponent } from './Pages/stats-general/stats-general.component';
import { AdminSectionsPageEditComponent } from './Pages/admin-sections-page-edit/admin-sections-page-edit.component';
import { AdminSectionsPagesComponent } from './Pages/admin-sections-pages/admin-sections-pages.component';
import { PageEditComponent } from './Pages/page-edit/page-edit.component';
import { PagesComponent } from './Pages/pages/pages.component';
import { ChangePasswordComponent } from './Pages/change-password/change-password.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {AppsLayoutComponent} from './Layout/apps-layout/apps-layout.component';

import {ForgotPasswordComponent} from './Pages/forgot-password/forgot-password.component';
import {LoginComponent} from './Pages/login/login.component';
import {StartComponent} from './Pages/start/start.component';
import { AdminUsersComponent } from './Pages/admin-users/admin-users.component';
import { AdminUserEditComponent } from './Pages/admin-user-edit/admin-user-edit.component';
import { AdEditComponent } from './Pages/ad-edit/ad-edit.component';
import { AdsComponent } from './Pages/ads/ads.component';
import { AdminCustomersComponent } from './Pages/admin-customers/admin-customers.component';
import { AdminCustomerEditComponent } from './Pages/admin-customer-edit/admin-customer-edit.component';
import { AdminTemplateEditComponent } from './Pages/admin-template-edit/admin-template-edit.component';
import { AdminTemplatesComponent } from './Pages/admin-templates/admin-templates.component';
import { AdminSectionsComponent } from './Pages/admin-sections/admin-sections.component';
import { AdminSectionEditComponent } from './Pages/admin-section-edit/admin-section-edit.component';
import { AdminAppsComponent } from './Pages/admin-apps/admin-apps.component';
import { AdminAppsEditComponent } from './Pages/admin-apps-edit/admin-apps-edit.component';
import { MediasComponent } from './Pages/medias/medias.component';
import { MediaUploadComponent } from './Pages/media-upload/media-upload.component';
import { MediaEditComponent } from './Pages/media-edit/media-edit.component';
import { MediaCropComponent } from './Pages/media-crop/media-crop.component';
import { ProfileComponent } from './Pages/profile/profile.component';
import { SerieEditComponent } from './Pages/serie-edit/serie-edit.component';
import { SeriesComponent } from './Pages/series/series.component';
import { NewsesComponent } from './Pages/newses/newses.component';
import { NewsEditComponent } from './Pages/news-edit/news-edit.component';
import { BylinesComponent } from './Pages/bylines/bylines.component';
import { BylineEditComponent } from './Pages/byline-edit/byline-edit.component';
import { AdminPlacesComponent } from './Pages/admin-places/admin-places.component';
import { AdminPlaceEditComponent } from './Pages/admin-place-edit/admin-place-edit.component';
import { LunchesComponent } from './Pages/lunches/lunches.component';
import { LunchEditComponent } from './Pages/lunch-edit/lunch-edit.component';
import { AdminEventsComponent } from './Pages/admin-events/admin-events.component';
import { AdminEventEditComponent } from './Pages/admin-event-edit/admin-event-edit.component';
import { FamilyAdsComponent } from './Pages/family-ads/family-ads.component';
import { FamilyEditComponent } from './Pages/family-edit/family-edit.component';
import { AdminRssesComponent } from './Pages/admin-rsses/admin-rsses.component';
import { AdminRssEditComponent } from './Pages/admin-rss-edit/admin-rss-edit.component';
import { AdminSportKeysComponent } from './Pages/admin-sport-keys/admin-sport-keys.component';
import { AdminSportKeyEditComponent } from './Pages/admin-sport-key-edit/admin-sport-key-edit.component';
import { AdminSportTeamsComponent } from './Pages/admin-sport-teams/admin-sport-teams.component';
import { AdminSportTeamEditComponent } from './Pages/admin-sport-team-edit/admin-sport-team-edit.component';
import { ContractsComponent } from './Pages/contracts/contracts.component';
import { ContractEditComponent } from './Pages/contract-edit/contract-edit.component';
import { ContactsComponent } from './Pages/contacts/contacts.component';
import { ContactEditComponent } from './Pages/contact-edit/contact-edit.component';
import { SalesContractsComponent } from './Pages/sales-contracts/sales-contracts.component';
import { SalesTimelineComponent } from './Pages/sales-timeline/sales-timeline.component';
import { FollowersComponent } from './Pages/followers/followers.component';
import { FollowerSingleComponent } from './Pages/follower-single/follower-single.component';
import { VideoUploadComponent } from './Pages/video-upload/video-upload.component';
import { AdminEmailTemplatesComponent } from './Pages/admin-email-templates/admin-email-templates.component';
import { AdminEmailTemplateEditComponent } from './Pages/admin-email-template-edit/admin-email-template-edit.component';
import { SupportVideosComponent } from './Pages/support-videos/support-videos.component';
import { AdminAppUsersComponent } from './Pages/admin-app-users/admin-app-users.component';
import { SupportVideos2Component } from './Pages/support-videos2/support-videos2.component';
import { AdminEventCategoryEditComponent } from './Pages/admin-event-category-edit/admin-event-category-edit.component';
import { QuizEditComponent } from './Pages/quiz-edit/quiz-edit.component';
import { AdminRecommendEditComponent } from './Pages/admin-recommend-edit/admin-recommend-edit.component';
import { AdminRecommendsAddComponent } from './Pages/admin-recommends-add/admin-recommends-add.component';
import { AdminDevicesPositionComponent } from './Pages/admin-devices-position/admin-devices-position.component';
import { ChristmasSignageComponent } from './Pages/christmas-signage/christmas-signage.component';
import { LunchStandardComponent } from './Pages/lunch-standard/lunch-standard.component';
import { DefaultLunchEditComponent } from './Pages/default-lunch-edit/default-lunch-edit.component';
import { StatsNewsComponent } from './Pages/stats-news/stats-news.component';
import { ProductEditComponent } from './Pages/product-edit/product-edit.component';
import { AdminGiftCardsComponent } from './Pages/admin-gift-cards/admin-gift-cards.component';
import { AdminGiftCardEditComponent } from './Pages/admin-gift-card-edit/admin-gift-card-edit.component';
import { IntranetStartComponent } from './Pages/intranet-start/intranet-start.component';
import { IntranetNewsStatsComponent } from './Pages/intranet-news-stats/intranet-news-stats.component';
import { CroniclesComponent } from './Pages/cronicles/cronicles.component';
import { LiveEventsComponent } from './Pages/live-events/live-events.component';
import { LiveEventComponent } from './Pages/live-event/live-event.component';
import { AdminMenuComponent } from './Pages/admin-menu/admin-menu.component';
import { AdminTabMenuComponent } from './Pages/admin-tab-menu/admin-tab-menu.component';
import { AdminSocialMediaComponent } from './Pages/admin-social-media/admin-social-media.component';
import { AdminFeedbacksComponent } from './Pages/admin-feedbacks/admin-feedbacks.component';
import { NewsEditSummaryComponent } from './Pages/news-edit-summary/news-edit-summary.component';
import { AdminAudioShowsComponent } from './Pages/admin-audio-shows/admin-audio-shows.component';
import { AdminAudioShowEditComponent } from './Pages/admin-audio-show-edit/admin-audio-show-edit.component';
import { AdminVideoShowsComponent } from './Pages/admin-video-shows/admin-video-shows.component';
import { AdminVideoShowEditComponent } from './Pages/admin-video-show-edit/admin-video-show-edit.component';
import { AdminTodaysComponent } from './Pages/admin-todays/admin-todays.component';
import { AdminTodayEditComponent } from './Pages/admin-today-edit/admin-today-edit.component';
import { ChatsComponent } from './Pages/chats/chats.component';
import { ChatComponent } from './Pages/chat/chat.component';
import { StatsComponent } from './Pages/stats/stats.component';
import { AdminAudioEpisodesComponent } from './Pages/admin-audio-episodes/admin-audio-episodes.component';
import { AdminEventTemplatesComponent } from './Pages/admin-event-templates/admin-event-templates.component';
import { AdminEventTemplateEditComponent } from './Pages/admin-event-template-edit/admin-event-template-edit.component';
import { AdminEventGenerateComponent } from './Pages/admin-event-generate/admin-event-generate.component';
import { AdminAudioOrphanEpisodesComponent } from './Pages/admin-audio-orphan-episodes/admin-audio-orphan-episodes.component';
import { SendNotificationComponent } from './Pages/send-notification/send-notification.component';
import { NotificationsComponent } from './Pages/notifications/notifications.component';
import { NotificationEditComponent } from './Pages/notification-edit/notification-edit.component';
import { SchoolCoursesComponent } from './Pages/school-courses/school-courses.component';
import { SchoolCourseEditComponent } from './Pages/school-course-edit/school-course-edit.component';
import { SchoolLessonsComponent } from './Pages/school-lessons/school-lessons.component';
import { SchoolLessonEditComponent } from './Pages/school-lesson-edit/school-lesson-edit.component';
import { SchoolTestComponent } from './Pages/school-test/school-test.component';


const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [

      // Dashboads

      {path: '', component: StartComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'ad/:id', component: AdEditComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'ads', component: AdsComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'medias', component: MediasComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'media/:id', component: MediaEditComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'crop/:id', component: MediaCropComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'upload', component: MediaUploadComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'uploadvideo', component: VideoUploadComponent, data: {extraParameter: 'adsMenu'}},
      {path: 'profile', component: ProfileComponent, data: {extraParameter: 'profileMenu'}},
      {path: 'contacts', component: ContactsComponent, data: {extraParameter: 'profileMenu'}},
      {path: 'contact/:id', component: ContactEditComponent, data: {extraParameter: 'profileMenu'}},
      {path: 'pages', component: PagesComponent, data: {extraParameter: 'profileMenu'}},
      {path: 'page/:id', component: PageEditComponent, data: {extraParameter: 'profileMenu'}},
      {path: 'products', component: ProductsComponent, data: {extraParameter: 'productMenu'}},
      {path: 'product/:id', component: ProductEditComponent, data: {extraParameter: 'productMenu'}},
      {path: 'quizzes', component: QuizzesComponent, data: {extraParameter: 'quizMenu'}},
      {path: 'quiz/:id', component: QuizEditComponent, data: {extraParameter: 'quizMenu'}},
      {path: 'quizlive/:id', component: QuizLiveComponent, data: {extraParameter: 'quizMenu'}},
      {path: 'lunches', component: LunchesComponent, data: {extraParameter: 'lunchMenu'}},
      {path: 'lunch/:id', component: LunchEditComponent, data: {extraParameter: 'lunchMenu'}},
      {path: 'standardlunch', component: LunchStandardComponent, data: {extraParameter: 'lunchMenu'}},
      {path: 'defaultlunches', component: DefaultLunchesComponent, data: {extraParameter: 'lunchMenu'}},
      {path: 'defaultlunch/:id', component: DefaultLunchEditComponent, data: {extraParameter: 'lunchMenu'}},
      {path: 'followers', component: FollowersComponent, data: {extraParameter: 'statsMenu'}},
      {path: 'follower/:id', component: FollowerSingleComponent, data: {extraParameter: 'statsMenu'}},
      {path: 'stats', component: StatsGeneralComponent, data: {extraParameter: 'statsMenu'}},
      {path: 'stats-news', component: StatsNewsComponent, data: {extraParameter: 'statsMenu'}},
      {path: 'contracts', component: ContractsComponent, data: {extraParameter: 'contractMenu'}},
      {path: 'contract/:id', component: ContractEditComponent, data: {extraParameter: 'contractMenu'}},
      {path: 'series', component: SeriesComponent, data: {extraParameter: 'newsMenu'}},
      {path: 'serie/:id', component: SerieEditComponent, data: {extraParameter: 'newsMenu'}},
      {path: 'newses', component: NewsesComponent, data: {extraParameter: 'newsMenu'}},
      {path: 'news-summary/:id', component: NewsEditSummaryComponent, data: {extraParameter: 'newsMenu'}},
      {path: 'news/:id', component: NewsEditComponent, data: {extraParameter: 'newsMenu'}},
      {path: 'bylines', component: BylinesComponent, data: {extraParameter: 'newsMenu'}},
      {path: 'byline/:id', component: BylineEditComponent, data: {extraParameter: 'newsMenu'}},
      {path: 'admin/allusers', component: AdminUsersComponent, data: {extraParameter: 'adminUserMenu'}},
      {path: 'familyads', component: FamilyAdsComponent, data: {extraParameter: 'familyNewsMenu'}},
      {path: 'family/:id', component: FamilyEditComponent, data: {extraParameter: 'familyNewsMenu'}},
      {path: 'admin/user/:id', component: AdminUserEditComponent, data: {extraParameter: 'adminUserMenu'}},
      {path: 'admin/allcustomers', component: AdminCustomersComponent, data: {extraParameter: 'adminCustomersMenu'}},
      {path: 'admin/customer/:id', component: AdminCustomerEditComponent, data: {extraParameter: 'adminCustomersMenu'}},
      {path: 'admin/alltemplates', component: AdminTemplatesComponent, data: {extraParameter: 'adminTemplatesMenu'}},
      {path: 'admin/template/:id', component: AdminTemplateEditComponent, data: {extraParameter: 'adminTemplatesMenu'}},
      {path: 'admin/allemailtemplates', component: AdminEmailTemplatesComponent, data: {extraParameter: 'adminTemplatesMenu'}},
      {path: 'admin/emailtemplate/:id', component: AdminEmailTemplateEditComponent, data: {extraParameter: 'adminTemplatesMenu'}},
      {path: 'admin/allsections', component: AdminSectionsComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/section/:id', component: AdminSectionEditComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/allsectionpages', component: AdminSectionsPagesComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/sectionpage/:id', component: AdminSectionsPageEditComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/allplaces', component: AdminPlacesComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/place/:id', component: AdminPlaceEditComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/allapps', component: AdminAppsComponent, data: {extraParameter: 'adminAppsMenu'}},
      {path: 'admin/app/:id', component: AdminAppsEditComponent, data: {extraParameter: 'adminAppsMenu'}},
      {path: 'admin/allevents', component: AdminEventsComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/event/:id', component: AdminEventEditComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/event/:id/:copyid', component: AdminEventEditComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/alleventtemplates', component: AdminEventTemplatesComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/generateevents', component: AdminEventGenerateComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/eventtemplate/:id', component: AdminEventTemplateEditComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/eventtemplate/:id/:copyid', component: AdminEventTemplateEditComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/allrecommends', component: AdminRecommendsComponent, data: {extraParameter: 'adminRecommendsMenu'}},
      {path: 'admin/allduplicatesrecommends', component: AdminRecommendsDuplicatesComponent, data: {extraParameter: 'adminRecommendsMenu'}},
      {path: 'admin/alladdrecommends', component: AdminRecommendsAddComponent, data: {extraParameter: 'adminRecommendsMenu'}},
      {path: 'admin/allrecommendsmap', component: AdminRecommendsMapComponent, data: {extraParameter: 'adminRecommendsMenu'}},
      {path: 'admin/recommend/:id', component: AdminRecommendEditComponent, data: {extraParameter: 'adminRecommendsMenu'}},
      {path: 'admin/alleventscategories', component: AdminEventCategoriesComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/eventcategory/:id', component: AdminEventCategoryEditComponent, data: {extraParameter: 'adminEventsMenu'}},
      {path: 'admin/allrsses', component: AdminRssesComponent, data: {extraParameter: 'adminRssMenu'}},
      {path: 'admin/rss/:id', component: AdminRssEditComponent, data: {extraParameter: 'adminRssMenu'}},
      {path: 'admin/allaudioshows', component: AdminAudioShowsComponent, data: {extraParameter: 'adminAudioMenu'}},
      {path: 'admin/audioshow/:id', component: AdminAudioShowEditComponent, data: {extraParameter: 'adminAudioMenu'}},   
      {path: 'admin/allaudioepisodes/:id', component: AdminAudioEpisodesComponent, data: {extraParameter: 'adminAudioMenu'}},   
      {path: 'admin/allorphanaudioepisodes', component: AdminAudioOrphanEpisodesComponent, data: {extraParameter: 'adminAudioMenu'}},   
      {path: 'admin/allvideoshows', component: AdminVideoShowsComponent, data: {extraParameter: 'adminVideoMenu'}},
      {path: 'admin/videoshow/:id', component: AdminVideoShowEditComponent, data: {extraParameter: 'adminVideoMenu'}},             
      {path: 'admin/allsportkeys', component: AdminSportKeysComponent, data: {extraParameter: 'adminSportMenu'}},
      {path: 'admin/sportkey/:id', component: AdminSportKeyEditComponent, data: {extraParameter: 'adminSportMenu'}},
      {path: 'admin/allsportteams', component: AdminSportTeamsComponent, data: {extraParameter: 'adminSportMenu'}},
      {path: 'admin/sportteam/:id', component: AdminSportTeamEditComponent, data: {extraParameter: 'adminSportMenu'}},
      {path: 'admin/allappusers', component: AdminAppUsersComponent, data: {extraParameter: 'adminAppUserMenu'}},
      {path: 'admin/appuser/:id', component: AdminAppUserEditComponent, data: {extraParameter: 'adminAppUserMenu'}},
      {path: 'admin/devices/position', component: AdminDevicesPositionComponent, data: {extraParameter: 'adminDevicesMenu'}},
      {path: 'admin/menu', component: AdminMenuComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/tabmenu', component: AdminTabMenuComponent, data: {extraParameter: 'adminSectionsMenu'}},
      {path: 'admin/socialmedia', component: AdminSocialMediaComponent, data: {extraParameter: 'adminSocialMediaMenu'}},
      {path: 'admin/feedbacks', component: AdminFeedbacksComponent, data: {extraParameter: 'adminFeedbackMenu'}},
      {path: 'admin/alltodays', component: AdminTodaysComponent, data: {extraParameter: 'adminTodayMenu'}},
      {path: 'admin/today/:id', component: AdminTodayEditComponent, data: {extraParameter: 'adminTodayMenu'}},
      {path: 'admin/feedbacks', component: AdminFeedbacksComponent, data: {extraParameter: 'adminFeedbackMenu'}},
      {path: 'sales/contracts', component: SalesContractsComponent, data: {extraParameter: 'salesMenu'}},
      {path: 'sales/timeline', component: SalesTimelineComponent, data: {extraParameter: 'salesMenu'}},
      {path: 'sales/warnings', component: SalesWarningsComponent, data: {extraParameter: 'salesMenu'}},
      {path: 'changePassword', component: ChangePasswordComponent, data: {extraParameter: 'settingsMenu'}},
      {path: 'support/videos', component: SupportVideos2Component, data: {extraParameter: 'supportMenu'}},
      {path: 'battlequestion/:id', component: BattleQuestionEditComponent, data: {extraParameter: 'BattleMenu'}},
      {path: 'battleallquestions', component: BattleQuestionsComponent, data: {extraParameter: 'BattleMenu'}},
      {path: 'events', component: EventsComponent, data: {extraParameter: 'eventMenu'}},
      {path: 'event/:id', component: EventEditComponent, data: {extraParameter: 'eventMenu'}},
      {path: 'event/new/:copyid', component: EventEditComponent, data: {extraParameter: 'eventMenu'}},
      {path: 'christmas', component: ChristmasSignageComponent, data: {extraParameter: 'productMenu'}},
      {path: 'admin/giftcards', component: AdminGiftCardsComponent, data: {extraParameter: 'adminGiftcardMenu'}},
      {path: 'admin/giftcard/:id', component: AdminGiftCardEditComponent, data: {extraParameter: 'adminGiftcardMenu'}},
      {path: 'intranet/start', component: IntranetStartComponent, data: {extraParameter: 'intranetMenu'}},
      {path: 'intranet/todos', component: IntranetTasksComponent, data: {extraParameter: 'intranetTodoMenu'}},
      {path: 'intranet/todo/:id', component: IntranetTaskEditComponent, data: {extraParameter: 'intranetTodoMenu'}},
      {path: 'cronicle/:id', component: CronicleEditComponent, data: {extraParameter: 'croniclesMenu'}},
      {path: 'cronicles', component: CroniclesComponent, data: {extraParameter: 'croniclesMenu'}},
      {path: 'live-events', component: LiveEventsComponent, data: {extraParameter: 'liveEventMenu'}},
      {path: 'live-event/:id', component: LiveEventComponent, data: {extraParameter: 'liveEventMenu'}},
      {path: 'admin/allchats', component: ChatsComponent, data: {extraParameter: 'adminChatMenu'}},
      {path: 'admin/chat/:id/:app_id', component: ChatComponent, data: {extraParameter: 'adminChatMenu'}},
      {path: 'admin/stats', component: StatsComponent, data: {extraParameter: 'adminStatsMenu'}},
      {path: 'admin/sendnotification', component: SendNotificationComponent, data: {extraParameter: 'adminNotificationMenu'}},
      {path: 'admin/notifications', component: NotificationsComponent, data: {extraParameter: 'adminNotificationMenu'}},
      {path: 'admin/notification/:id', component: NotificationEditComponent, data: {extraParameter: 'adminNotificationMenu'}},
      {path: 'courses', component: SchoolCoursesComponent, data: {extraParameter: 'schoolMenu'}},
      {path: 'course/:id', component: SchoolCourseEditComponent, data: {extraParameter: 'schoolMenu'}},
      {path: 'lessons/:id', component: SchoolLessonsComponent, data: {extraParameter: 'schoolMenu'}},
      {path: 'lesson/:id/:chapter', component: SchoolLessonEditComponent, data: {extraParameter: 'schoolMenu'}},
      {path: 'test/:id', component: SchoolTestComponent, data: {extraParameter: 'schoolMenu'}},

    ]

  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [

      {path: 'login', component: LoginComponent, data: {extraParameter: ''}},
      {path: 'lost', component: ForgotPasswordComponent, data: {extraParameter: ''}},
      {path: 'supportVideos', component: SupportVideosComponent, data: {extraParameter: ''}}
    ]
  },
  {path: '**', redirectTo: '/login'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
