import { Component, OnInit } from '@angular/core';
import { AppUser } from '../../../../../shared/models/appuser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { Response } from '../../../../../shared/models/response';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-admin-app-user-edit',
  templateUrl: './admin-app-user-edit.component.html',
  styleUrls: ['./admin-app-user-edit.component.scss']
})
export class AdminAppUserEditComponent implements OnInit {

  heading = 'Skapa prenumerant';
  subheading = 'Här skapar du en ny prenumerant';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  public user: AppUser = new AppUser();
  // public apps: any[] = [];
  public apps: App[] = [];
  public tempApps: any[] = [];
  public getApps: any[] = [];

  constructor(private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private data: DataService) {

    let id = +this.route.snapshot.paramMap.get("id");
    this.apps = this.data.currentUser.apps;

    if (id > 0)
    {
        this.api.getAppUser(id).subscribe((data:AppUser) => {
          this.user = data;

          const apps = JSON.parse(this.user.apps)
            apps.map((appId)=> {
            const app = this.apps.find(obj => obj.id === appId)
            this.getApps.push(app)
          })

          this.tempApps = this.getApps
        })
    }
   }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;
  }

  save() {
    const apps = []

    this.tempApps.forEach(data => {
      apps.push(data.id)
    });

    const appsSting = JSON.stringify(apps)
    this.user.apps = appsSting

    this.api.setAppUser(this.user).subscribe((data:Response) => {
      if (data.code == 200)
      {
        this.router.navigateByUrl('/admin/allappusers')
      }
    })

  }

  onChange() {
    console.log(this.tempApps)
  }

  public delete()
  {
    this.api.delAppUser(this.user.id).subscribe((data:Response) => {
      console.log(data)
        this.router.navigateByUrl('/admin/allappusers')
    })

  }

}
