import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { ToastrService } from 'ngx-toastr';


import { Lesson } from '../../../../../shared/models/lesson';

@Component({
  selector: 'app-school-lesson-edit',
  templateUrl: './school-lesson-edit.component.html',
  styleUrls: ['./school-lesson-edit.component.scss']
})
export class SchoolLessonEditComponent implements OnInit {
  private id: number
  private chapter: number
  public lesson:Lesson;
  public title:string

  constructor(
    private api: ApiService,
    private route: ActivatedRoute, 
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get("id");
    this.chapter = +this.route.snapshot.paramMap.get("chapter")
    this.loadLesson()
    
  }
  
  loadLesson() {
    if (this.id === 0 ) {
      this.lesson = new Lesson
      return
    }
    this.api.getLesson(this.id).subscribe((data:Lesson) => {
      this.lesson = data[0];
      console.log(this.lesson)
    })
  }

  save() {
    if (this.id === 0 ) {
      this.lesson.chapter_id = this.chapter
    }
    // console.log(this.lesson)
    this.api.setLesson(this.lesson).subscribe((data:Response)=> {
      
      this.toastr.success("Nyheten sparades", "Sparad", { progressBar: true, closeButton: true });
      this.router.navigate(['/lessons/' + this.chapter])
    }, error => this.api.showError(error));
  }

  delete() {}

}
